<template>
  <div class="body-1">
    <v-card>
      <v-card-title>
        Configuration List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="configurationList"
          :loading="isLoadingConfigurations"
          :search="search"
          :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
          :items-per-page="50"
          class="elevation-1"
        >
          <template v-slot:item.configurationName="{item}">
            <router-link
              :to="{name: 'adminConfigurationEdit',params: { configId: item.configurationId }}"
            >{{item.configurationName}}</router-link>
          </template>

          <template v-slot:no-data>
            <td>No data found</td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :to="{ name: 'adminConfigurationEdit', params: { configId: 'new'}}"
        >Add New Configuration</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

let headers = [
  {
    text: "Configuration Name",
    value: "configurationName",
    sortable: true
  },
  {
    text: "Configuration ID",
    value: "configurationId",
    sortable: true
  },
  {
    text: "Alert Duration (seconds)",
    value: "configurationDuration"
  },
  {
    text: "Incidents",
    value: "incidents.length"
  },
  {
    text: "Resources",
    value: "resources.length"
  },
  {
    text: "Notifications",
    value: "notify.length"
  }
];

export default {
  data() {
    return {
      search: "",
      headers: headers,
      pagination: {
        sortBy: "configurationName"
      }
    };
  },
  computed: mapState({
    isLoadingConfigurations: state => state.admin.isLoadingConfigurations,
    configurationList: state => state.admin.configurationList
  }),
  methods: {
    ...mapActions(["loadAdminConfigurationList"])
  },
  created() {
    this.loadAdminConfigurationList();
  }
};
</script>

